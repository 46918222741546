import React from 'react'
import './style.scss'
import { Navbar } from 'react-bootstrap'
import { Nav } from 'react-bootstrap'

const Header = () => (
  <div class="d-flex align-items-center flex-column">
    <div className="col-lg-5 col-md-6 col-sm-8 col-xs-10 px-0">
      <Navbar.Brand href="/">
        <img
          className="img-responsive pt-1 ml-2 headerLogo"
          src={require('../../../static/assets/images/logo/Transparent logo with mandala for grey background 2.png')}
          alt="Logo"
        />
      </Navbar.Brand>
    </div>
    <div id="nav-link-area" className="row pt-8">
      <div className="col">
        <Nav className="navbar-nav align-items-center mr-auto">
          <Nav.Link href="../../index.html">Home</Nav.Link>
          <Nav.Link href="/about">About</Nav.Link>
          <Nav.Link href="/schedule">Schedule</Nav.Link>
          <Nav.Link href="/pricing">Pricing</Nav.Link>
          <Nav.Link href="/contact">Contact</Nav.Link>
          <Nav.Link href="/gallery">Gallery</Nav.Link>
          <Nav.Link href="/blog">News</Nav.Link>
        </Nav>
      </div>
    </div>
  </div>
)

export default Header
